import React, { useEffect, useRef } from "react";

interface BannerProps {
  text: string;
}

const Banner: React.FC<BannerProps> = ({ text }) => {
  const bannerTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const bannerText = bannerTextRef.current;

    if (bannerText) {
      bannerText.animate(
        [
          { transform: "translateX(100vw)" },
          { transform: "translateX(-100%)" },
        ],
        {
          duration: 10000,
          iterations: Infinity,
          easing: "linear",
        }
      );
    }
  }, []);

  return (
    <div
      style={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100%",
        paddingTop: 3,
        paddingBottom: 3,
      }}
      className="bg-gray-700"
    >
      <div
        ref={bannerTextRef}
        style={{
          fontFamily: "Doto",
          fontSize: 25,
          fontWeight: "600",
          color: "white",
          display: "inline-block",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default Banner;
